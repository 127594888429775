<template lang="html">
  <v-container pa-0 fluid>
    <v-row dense>
      <v-col cols="12">
        <v-patient-bar @patientLoaded="onPatientLoaded" @patientUnloaded="onPatientUnloaded"></v-patient-bar>
      </v-col>
      <v-col cols="12" v-if="currentPatient">
        <v-tabs-group>
          <template v-slot:tabs>
            <v-tab key="general">ข้อมูลทั่วไป</v-tab>
            <v-tab key="name">ชื่อ</v-tab>
            <v-tab key="address">ที่อยู่</v-tab>
            <v-tab key="relative">ผู้ติดต่อ</v-tab>
            <v-tab key="photo">รูปถ่าย</v-tab>
            <v-tab key="document">เอกสารส่วนตัว</v-tab>
          </template>
          <template v-slot:items>
            <v-tab-item key="general">
              <v-general-info v-model="currentPatient"></v-general-info>
            </v-tab-item>
            <v-tab-item key="name">
              <v-name-table :hn="currentPatient.hn"></v-name-table>
            </v-tab-item>
            <v-tab-item key="address">
              <v-address-table :hn="currentPatient.hn"></v-address-table>
            </v-tab-item>
            <v-tab-item key="relative">
              <v-relative-table :hn="currentPatient.hn"></v-relative-table>
            </v-tab-item>
            <v-tab-item key="photo">
              <v-patient-photo-list :hn="currentPatient.hn"></v-patient-photo-list>
            </v-tab-item>
            <v-tab-item key="document">
              <v-document-scan title="Personal Documents" :hn="currentPatient.hn" folder="personal" defaultCategory="100" :availableCategory="['100','101','102','103','104','109']" refreshable></v-document-scan>
            </v-tab-item>
          </template>
        </v-tabs-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import patient from '@/modules/helperPatient'
import {isUndefined} from 'lodash'

export default {
  data: ()=>({
    currentPatient: undefined,
  }),
  props: {
    hn: {
      type: String,
      default: undefined
    }
  },
  watch: {
    hn: function(newVal) {
      this.loadPatient(this.hn)
    }
  },
  methods: {
    loadPatient(hn) {
      patient.getPatientByHn(hn).then((returnData)=>{
        this.currentPatient = returnData
        this.$forceUpdate()
      }).catch((error)=>{
        this.reset()
      })
    },
    reset() {
      this.currentPatient = undefined
    },
    onPatientLoaded(patient) {
      this.currentPatient = patient
    },
    onPatientUnloaded() {
      this.reset()
    }
  },
  mounted() {
    if (!isUndefined(this.hn)) this.loadPatient(this.hn)
  }
}
</script>

<style lang="css">
</style>
